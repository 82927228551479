<template>
  <iframe :src="webUrl+'/admin/price/discount-b'"></iframe>
</template>

<script>
export default {
  name: "price_b",
  emits : [ 'setMenuKey' ],
  setup(props,{emit}){
    emit('setMenuKey','gj.jgcxb');
    return {
      "webUrl" : process.env.VUE_APP_DAILIAO_WEB_URL
    }
  }
}
</script>

<style scoped>

</style>